const leaflets = {
    "demo": {
        "config": {
            "primary_color": "#005CB8",
            "secondary_color": "#FE8000",
            "tertiary_color": "#fecf40",
            "hover_color": "rgba(220, 35, 17, 0.5)",
            "clientIcon": require("assets/images/eurospin_logo.png"),
            "serverPath": "eurospinviaggi.interattivo.net/",
            "pdfPath": "/media/pdf/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino di Volantino Interattivo!",
            "shareFlyerURL": "https://eurospinviaggi.interattivo.net?v=587",
            "shareProductSentence": "Guarda questa fantastica offerta!",
            "ogTitleMeta": "Eurospin viaggi | Volantino Interattivo",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://eurospinviaggi.interattivo.net/media/demo_badil/images/share_fb_1.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": false,
            "ga_tracking_id": "UA-152069090-38",
            "release_id": "1",
            "send_grocery_list_to_market": true,
            "grocery_list_min_value": 10,
            "client_id": 38,
            "signboard_id": 60,
            "has_pages": true,
            "type": "leaflet",
            "category_banner": "/media/images/banner.jpg",
            "category_banner_mobile": "/media/images/banner_mobile.jpg",
            "product_banner": "",
            "href_banner": "",
            "internal_banner_click": false,
            "external_banner_click": false,
            "add_icon_list": true,
            "pager": "buttons",
            "hide_share_product": false,
            "hide_grocery_list": true,
            "version": 587
        },
        "leaflet": {
            "name": "Eurospin viaggi",
            "code": "587",
            "id_campaign": "587",
            "index": {
                "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/pages/Indice-demo-Eurospin-ok_8VVwAnh.png",
                "links": [
                    {
                        "page": 1,
                        "blueprint": {
                            "top": 29.622492914301596,
                            "left": 9.557125088841506,
                            "width": 81.75453091684435,
                            "height": 8.772071740693205
                        }
                    },
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 38.3945646549948,
                            "left": 9.557125088841506,
                            "width": 81.75453091684435,
                            "height": 10.097864293421933
                        }
                    },
                    {
                        "page": -1,
                        "type": "phone",
                        "url": "+390457861834",
                        "target": "_blank",
                        "blueprint": {
                            "top": 54.408480040473414,
                            "left": 11.074770258980784,
                            "width": 40.649644945697574,
                            "height": 9.228152701008064
                        }
                    },
                    {
                        "page": -1,
                        "type": "url",
                        "url": "https://www.eurospin-viaggi.it",
                        "target": "_blank",
                        "blueprint": {
                            "top": 54.408480040473414,
                            "left": 51.72441520467836,
                            "width": 41.1921470342523,
                            "height": 9.228152701008067
                        }
                    },
                    {
                        "page": -1,
                        "type": "url",
                        "url": "https://interattivo.net",
                        "target": "_blank",
                        "blueprint": {
                            "top": 82.22802891265387,
                            "left": 31.061090225563913,
                            "width": 37.586048454469505,
                            "height": 6.471260470431625
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Montagna",
                    "subcategories": [
                        "Montagna"
                    ]
                },
                {
                    "category_name": "Città",
                    "subcategories": [
                        "Città"
                    ]
                },
                {
                    "category_name": "Villaggi",
                    "subcategories": [
                        "Villaggi"
                    ]
                },
                {
                    "category_name": "Crociera",
                    "subcategories": [
                        "Crociera"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/pages/166_2021_01_TABLOID_EUROSPIN_Promo24_page-0001_vLvle96.jpg",
                    "products": [
                        {
                            "item_id": "cstm18750518",
                            "field1": "Monte bondone (TN)",
                            "field2": "Hotel norge",
                            "field3": "3, 4 o 7 notti + Trentino Guest Card + utilizzo area benessere + 1 buono Wellness a persona",
                            "field4": "",
                            "description": "Monte bondone (TN) Hotel norge 3, 4 o 7 notti + Trentino Guest Card + utilizzo area benessere + 1 buono Wellness a persona",
                            "grammage": null,
                            "price": "143.00",
                            "price_label": "Da 143,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Montagna",
                            "subcategory": "Montagna",
                            "blueprint": {
                                "top": 11.777756769025402,
                                "left": 5.382050768732401,
                                "width": 44.627643010821785,
                                "height": 26.184875516387226
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/59377_z_Zodiaco_Monte_Bondone_piste_G_1_Eobf4bV.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42226_z_Norge_Monte_Bondone_spa2_G_1_1_1_EZI4SiT.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42225_z_Norge_Monte_Bondone_spa3_G_1_1_1_d7QNrzZ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42223_z_Norge_Monte_Bondone_ristorante_G_1_1_1_tsywqX1.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42222_z_Norge_Monte_Bondone_spa1_G_1_1_1_1qNK6Bg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42221_z_Norge_Monte_Bondone_camera_G_1_1_1_lU2vmJN.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42220_z_Norge_Monte_Bondone_bar_G_1_1_1_sCDu7pz.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_Aw4v7Is_OdHIXgO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_norge_5"
                                },
                                {
                                    "type": "specs",
                                    "title": "SERVIZI ED ESPERIENZE INCLUSE",
                                    "data": {
                                        "specifiche": "3- 4 o 7 notti + Trentino Guest Card + utilizzo area benessere + 1 buono Wellness a persona, Mezza pensione, Bimbo gratis, Utilizzo Spa/Centro Benessere, Utilizzo Piscina, Cenone Di Capodanno, Cena Di Natale, Sconto Servizi, Card Turistica, Bonus Vacanze",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/59377_z_Zodiaco_Monte_Bondone_piste_G_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "ALTITUDINE: 1450 metri.\n\nL'Hotel Norge, immerso nella natura, sorge in posizione panoramica sul Monte Bondone e su Trento.\n\nA disposizione degli ospiti reception (dalle 08:00 dalle 22:30), ascensore, sala Tv, ristorante (su richiesta menu per intolleranze alimentari e menu per vegani), bar, giardino, area giochi per bambini, biliardo, noleggio bici (a pagamento in loco), piscina coperta attrezzata con lettini secondo disponibilità, centro benessere (aperto dalle 14:00 alle 19:30, accesso consentito a partire dai 16 anni) che include sauna, solarium, bagno turco e idromassaggio, massaggi e trattamenti (a pagamento in loco), terrazza, garage (a pagamento in loco), connessione Wi-Fi nelle aree comuni e parcheggio secondo disponibilità.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/42220_z_Norge_Monte_Bondone_bar_G_1_1_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm64243371",
                            "field1": "Nova ponente (BZ)",
                            "field2": "Hotel pietralba",
                            "field3": "3, 4 o 7 notti mezza pensione",
                            "field4": "",
                            "description": "Nova ponente (BZ) Hotel pietralba 3, 4 o 7 notti mezza pensione",
                            "grammage": null,
                            "price": "162.00",
                            "price_label": "Da 162,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Montagna",
                            "subcategory": "Montagna",
                            "blueprint": {
                                "top": 11.777756769025402,
                                "left": 50.00969377955419,
                                "width": 44.83064613263062,
                                "height": 26.184875516387226
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/59512_z_Pietralba_Nova_Ponente_esterno4_G_1_fvZO19n.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/59513_z_Pietralba_Nova_Ponente_camera_G_1_v8iUGtv.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/59514_z_Pietralba_Nova_Ponente_esterno1_G_1_e34g1qm.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/59516_z_Lady_Maria_Fondo_famiglia_neve_G_1_mDGNjRP.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/59515_z_Pietralba_Nova_Ponente_esterno3_G_1_FliHOIg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_qliVFXf_nhw5x1V.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_pietralba_4"
                                },
                                {
                                    "type": "specs",
                                    "title": "LA QUOTA COMPRENDE",
                                    "data": {
                                        "specifiche": "Mezza pensione con colazione servita al tavolo e cena servita con menu di 3 portate a scelta, Cena di Natale (il 24/12), Cenone di Capodanno (il 31/12), Connessione Wi-Fi, Parcheggio secondo disponibilità, Culla per infant 0-3 anni non compiuti su richiesta alla prenotazione secondo disponibilità.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/59516_z_Lady_Maria_Fondo_famiglia_neve_G_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "ALTITUDINE: 1520 metri \n\nL'Hotel Pietralba è situato nel cuore delle Dolomiti, abbracciato dalla natura più incontaminata a pochi passi dal Santuario della Madonna di Pietralba. \n\nA disposizione degli ospiti reception (dalle 09 alle 14 e dalle 18 alle 21), ascensore, bar, ristorante (su richiesta vengono forniti menu per celiaci), giardino, terrazza, connessione Wi-Fi nelle aree comuni (gratuita), parcheggio secondo disponibilità (gratuito), culla per infant 0-3 anni non compiuti su richiesta alla prenotazione secondo disponibilità (gratuita).\n\nLe camere Standard dispongono di servizi privati con doccia, cassaforte, minibar, Tv-Sat e balcone. Le camere quadruple sono con letto a castello.\n\nAMICI A 4 ZAMPE: Ammessi di piccola taglia (5 euro al giorno da pagare in loco), su richiesta al call center al momento della prenotazione.\n\nSKI AREA: Ski center Latemar / Val di Fiemme. Distanza dalle piste: 19 Km.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/59512_z_Pietralba_Nova_Ponente_esterno4_G_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm70351593",
                            "field1": "Lavarone (TN)",
                            "field2": "Hotel monteverde",
                            "field3": "3, 5 o 6 notti, Mezza pensione con bevande",
                            "field4": "",
                            "description": "Lavarone (TN) Hotel monteverde 3, 5 o 6 notti, Mezza pensione con bevande",
                            "grammage": null,
                            "price": "135.00",
                            "price_label": "Da 135,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Montagna",
                            "subcategory": "Montagna",
                            "blueprint": {
                                "top": 37.962632285412624,
                                "left": 72.49516141105168,
                                "width": 22.345178501133123,
                                "height": 25.840046601992835
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2178_z_Monteverde_Lavarone_esterno1_G_2_1_1_1_1_1_1_anxj3pY.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2181_z_Monteverde_Lavarone_veduta_G_2_1_1_1_1_1_1_XrQ6k7E.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2177_z_Monteverde_Lavarone_esterno_G_2_1_1_1_1_1_1_ToK0GID.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2176_z_Monteverde_Lavarone_camera2_G_2_1_1_1_1_1_1_f1NQoNg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2180_z_Monteverde_Lavarone_sala_G_2_1_1_1_1_1_1_AF7xaSc.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2179_z_Monteverde_Lavarone_Ristorante_G_2_1_1_1_1_1_1_QDvPYpX.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_Gcj2yf5_WX40BGr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_monteverde_13"
                                },
                                {
                                    "type": "specs",
                                    "title": "SERVIZI ED ESPERIENZE INCLUSE",
                                    "data": {
                                        "specifiche": "3- 5 o 6 notti, Mezza pensione con bevande, Bimbo gratis, Animali Gratis, Cena Di Natale, Bonus Vacanze",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/2179_z_Monteverde_Lavarone_Ristorante_G_2_1_1_1_1_1_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "ALTITUDINE: 1180 metri.\n\nL'Hotel Monteverde è una struttura piacevole e moderna situata nel centro di Lavarone, in posizione soleggiata e tranquilla.\n\nA disposizione degli ospiti reception (dalle 08:00 alle 22:00), ascensore, bar, ristorante, sala TV, deposito sci, giardino, connessione Wi-Fi nelle aree comuni (gratuita), parcheggio secondo disponibilità (gratuito), culla su richiesta alla prenotazione secondo disponibilità (gratuita).\n\nLe camere sono dotate di servizi privati, telefono, TV via cavo, asciugacapelli, cassaforte, connessione Wi-Fi (gratuita). Le camere quadruple sono con letto a castello.\n\nAMICI A 4 ZAMPE: Ammessi di piccola taglia (solo cani; gratuiti - non sono ammessi nelle aree comuni), previa segnalazione al call center al momento della prenotazione.\n\nSKI AREA: Lavarone. Distanza dagli impianti: 1 km.\n\nAVVISO IMPORTANTE: Per accedere alla struttura è richiesto il Green Pass a tutti gli ospiti al di sopra dei 12 anni, in quanto il ristorante è aperto anche a clienti esterni.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/2181_z_Monteverde_Lavarone_veduta_G_2_1_1_1_1_1_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm30194583",
                            "field1": "Passo tonale (TN)",
                            "field2": "Hotel gardenia",
                            "field3": "7 notti + utilizzo centro benessere e piscina",
                            "field4": "",
                            "description": "Passo tonale (TN) Hotel gardenia 7 notti + utilizzo centro benessere e piscina",
                            "grammage": null,
                            "price": "423.00",
                            "price_label": "Da 423,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Montagna",
                            "subcategory": "Montagna",
                            "blueprint": {
                                "top": 37.962632285412624,
                                "left": 49.99029791961702,
                                "width": 22.504863491434655,
                                "height": 25.840046601992835
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2770_z_Gardenia_Vermiglio_Tonale_esteno_G_1_1_1_2_1_1_1_GblH1bE.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2769_z_Gardenia_Vermiglio_Tonale_camera_G_2_1_1_3_1_1_1_ENqCz40.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2768_z_Gardenia_Vermiglio_Tonale_benessere_G_2_1_1_3_1_1_1_cfMsR69.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2773_z_Gardenia_Vermiglio_Tonale_ristorante_G_2_1_1_3_1_1_1_2xAiQ6u.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2772_z_Gardenia_Vermiglio_Tonale_piscina_G_2_1_1_3_1_1_1_8YFhk4T.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/2771_z_Gardenia_Vermiglio_Tonale_hotel_G_2_1_1_3_1_1_1_qjG4G4y.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_CVPRdw9_w6eqKIZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_gardenia_14"
                                },
                                {
                                    "type": "specs",
                                    "title": "",
                                    "data": {
                                        "specifiche": "7 notti + utilizzo centro benessere e piscina, Mezza pensione, Bimbo gratis, Utilizzo Spa/Centro Benessere, Utilizzo Piscina, Cena Di Natale, Skibus, Bonus Vacanze",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/2771_z_Gardenia_Vermiglio_Tonale_hotel_G_2_1_1_3_1_1_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "ALTITUDINE: 1884 metri\n\nL'Hotel Gardenia si trova sul Passo del Tonale, a pochi passi dal centro e coniuga comfort ed accoglienza al calore della gestione a carattere familiare.\n\nA disposizione degli ospiti reception (dalle 07:00 alle 24:00), bar, ristorante, deposito sci, terrazza, sala TV, connessione Wi-Fi nelle aree comuni (gratuita), parcheggio secondo disponibilità (gratuito), culla su richiesta alla prenotazione secondo disponibilità (10 euro a notte da pagare in loco), garage secondo disponibilità (50 euro a settimana da pagare in loco), servizio navetta da/per gli impianti ad orari prestabiliti (su richiesta - gratuito), centro benessere (orari di apertura: dalle 14:30 alle 19:00 - accesso consentito solo agli adulti) con sauna finlandese, idromassaggio, frigidarium, paradiso dell'acqua, zone relax, palestra, cabine per massaggi e trattamenti e solarium (a pagamento), piscina coperta per adulti e bambini (orari di apertura: dalle 10:00 alle 12:00 e dalle 14:30 alle 19:00 - utilizzo dell'accappatoio, della cuffia e del telo obbligatori, è possibile portarli da casa - non è consentito utilizzare il telo presente in camera - eventuale noleggio a pagamento in loco).\n\nLe ampie camere Standard sono dotate di servizi privati, TV/Sat, asciugacapelli, telefono, riscaldamento, cassaforte, connessione Wi-Fi (gratuita). Le camere quadruple sono con letto a castello e potrebbero essere mansardate.\n\nSu richiesta al Call Center: camere con balcone con supplemento di 70 euro a settimana da pagare alla prenotazione.\n\nSKI AREA: Pontedilegno Tonale. Distanza dagli impianti: circa 450 metri.\n\nAMICI A 4 ZAMPE: Non ammessi.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/2770_z_Gardenia_Vermiglio_Tonale_esteno_G_1_1_1_2_1_1_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm39435550",
                            "field1": "Andalo (TN)",
                            "field2": "Hotel andalo",
                            "field3": "4, 5, 6 o 7 notti mezza pensione + utilizzo sauna e idromassaggio",
                            "field4": "",
                            "description": "Andalo (TN) Hotel andalo 4, 5, 6 o 7 notti mezza pensione + utilizzo sauna e idromassaggio",
                            "grammage": null,
                            "price": "251.00",
                            "price_label": "Da 251,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Montagna",
                            "subcategory": "Montagna",
                            "blueprint": {
                                "top": 37.962632285412624,
                                "left": 27.645119418483894,
                                "width": 22.345178501133123,
                                "height": 25.840046601992835
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22651_z_Hotel_Andalo_Andalo_sella-ronda_G_1_1_1_1_E2SUW8K.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22652_z_Hotel_Andalo_Andalo_esterno_G_1_1_1_1_8VrIn7L.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22653_z_Hotel_Andalo_Andalo_camera_G_1_1_1_1_8I0uj4r.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22654_z_Hotel_Andalo_Andalo_spa_G_1_1_1_1_OAoBCpc.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22655_z_Hotel_Andalo_Andalo_ristorante_G_1_1_1_1_SdX5Mzk.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22656_z_Hotel_Andalo_Andalo_sala_G_1_1_1_1_HS3Ds3v.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_l6r45Yy_xpjUWid.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_andalo_6"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "ALTITUDINE: 1037 metri.\n\nL'Hotel Andalo si trova a 100 metri dal centro di Andalo e a 170 metri dalla cabinovia, in posizione soleggiata e tranquilla con vista sulle Dolomiti di Brenta e sul massicco della Paganella. Grazie alla sua posizione e all'accoglienza cordiale, è una struttura particolarmente adatta alle famiglie. Dista 8 km dal Lago di Molveno.\n\nA disposizione degli ospiti reception (dalle 07:30 alle 24:00), ascensore, ristorante (su richiesta vengono forniti prodotti per celiaci), bar, sala TV, giardino, connessione internet Wi-Fi nella hall (gratuita), parcheggio secondo disponibilità (gratuito), garage secondo disponibilità (5 euro al giorno da pagare in loco), area giochi per bambini, culla per infant 0-2 anni non compiuti su richiesta al momento della prenotazione secondo disponibilità (8 euro al giorno da pagare in loco), area benessere (aperta ad orari prestabiliti - ingresso continentato) con sauna, idromassaggio e solarium viso (8 euro a gettone da pagare in loco).\n\nLe camere Classic dispongono di servizi privati con doccia o vasca, asciugacapelli, set di cortesia, accappatoio (su richiesta noleggio 10 euro per intero periodo ad accapptoio), TV-Sat, telefono, riscaldamento, cassaforte, alcune con balcone. Le camere quadruple dispongono di letto a castello. Possibilità di camere singole (su richiesta alla prenotazione – con supplemento come da tabella prezzi).\n\nAMICI A 4 ZAMPE: Ammessi di piccola taglia (50 euro a soggiorno da pagare in loco per pulizia finale), previa segnalazione al call center al momento della prenotazione.\n\nSKI AREA: Andalo - Paganella. Distanza dagli impianti: 170 metri.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/22652_z_Hotel_Andalo_Andalo_esterno_G_1_1_1_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "LA QUOTA COMPRENDE",
                                    "data": {
                                        "specifiche": "Mezza pensione con prima colazione a buffet dolce e salato e cena con servizio al tavolo con menu di 3 portate + selezione di contorni, Cena di Natale per la sera del 24/12, Ricca Cena di Capodanno per la sera del 31/12, Utilizzo della sauna e dell'idromassaggio (solo su prenotazione - ingresso contingentato con turni di 30 minuti; accesso consentito dai 14 anni in poi), Animazione serale per bambini (solo in alta stagione e secondo programma disponibile in loco), Connessione Wi-Fi nella hall, Parcheggio secondo disponibilità.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/22654_z_Hotel_Andalo_Andalo_spa_G_1_1_1_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm33021094",
                            "field1": "Primiero- passocereda (TN)",
                            "field2": "Rigugio cereda",
                            "field3": "2, 3 o 4 notti mezza pensione",
                            "field4": "",
                            "description": "Primiero- passocereda (TN) Rigugio cereda 2, 3 o 4 notti mezza pensione",
                            "grammage": null,
                            "price": "76.00",
                            "price_label": "Da 76,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Montagna",
                            "subcategory": "Montagna",
                            "blueprint": {
                                "top": 37.962632285412624,
                                "left": 5.019373724393761,
                                "width": 22.62574569409013,
                                "height": 25.840046601992835
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/73990_z_Rifugio_Cereda_Transaqua_inverno_esterno2_G_WjXcBHV.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/58712_z_Rifugio_Cereda_Transaqua_ristorante_G_1_TmAbjB6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54200_z_Rifugio_Cereda_Transaqua_servizi_G_1_GGvcxvf.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54199_z_Rifugio_Cereda_Transaqua_colazione_G_1_TEt9X9B.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54196_z_Rifugio_Cereda_Transaqua_img2_G_1_kxIVG5w.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54195_z_Rifugio_Cereda_Transaqua_camera2_G_1_UD00fYt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54194_z_Rifugio_Cereda_Transaqua_camera1_G_1_3EnI6fH.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_Ftx0z8R_tFI5FJ8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/rifugio_cereda_6"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "ALTITUDINE: 1369 metri.\n\nIl Rifugio Cereda si trova a 8 km da Primiero, lungo la strada del Passo Cereda, che collega il Trentino e la provincia di Belluno.\n\nA disposizione degli ospiti reception (dalle 08:00 alle 22:00), ristorante (su richiesta vengono forniti menu per intolleranze alimentari), bar, deposito biciclette, connessione Wi-Fi nelle aree comuni (gratuita), giardino, terrazza, parcheggio secondo disponibilità (gratuito), culla per infant 0-3 anni non compiuti su richiesta alla prenotazione secondo disponibilità (5 euro al giorno da pagare in loco), biliardino.\n\nLe camere Standard (circa 12-20 mq) dispongono di servizi privati con doccia e set di cortesia, riscaldamento, asciugacapelli (su richiesta), alcune con terrazza o balcone. Le camere quadruple dispongono di letto a castello.\n\nAMICI A 4 ZAMPE: non ammessi.\n\nSKI AREA: San Martino di Castrozza - Passo Rolle. Distanza dagli impianti: 20 km.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/73990_z_Rifugio_Cereda_Transaqua_inverno_esterno2_G.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "",
                                    "data": {
                                        "specifiche": "Mezza pensione con prima colazione a buffet e cena con servizio al tavolo a scelta tra 2 menu ciascuno composto da 1 primo- 1 secondo con contorno- dolce o frutta (nella giornata del 31/12/2021 il trattamento di mezza pensione prevede prima colazione e pranzo), Connessione Wi-Fi nelle aree comuni, Parcheggio (secondo disponibilità)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/58712_z_Rifugio_Cereda_Transaqua_ristorante_G_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm19181616",
                            "field1": "Palleusieux (AO)",
                            "field2": "Hotel alpechiara",
                            "field3": "4, 5, 6 o 7 notti + utilizzo piccolo centro benessere + miniclub",
                            "field4": "",
                            "description": "Palleusieux (AO) Hotel alpechiara 4, 5, 6 o 7 notti + utilizzo piccolo centro benessere + miniclub",
                            "grammage": null,
                            "price": "215.00",
                            "price_label": "Da 215,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Montagna",
                            "subcategory": "Montagna",
                            "blueprint": {
                                "top": 63.80267888740546,
                                "left": 5.019373724393761,
                                "width": 22.62574569409013,
                                "height": 25.977196742323024
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22596_z_Alpechiara_Aosta_sciatori1_G_2_1_1_1kLMceg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22597_z_Alpechiara_Aosta_esterno_G_2_1_1_JIxGOIg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22598_z_Alpechiara_Aosta_camera_G_2_1_1_gFOWBzz.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22599_z_Alpechiara_Aosta_hall_G_2_1_1_dhdSekz.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22600_z_Alpechiara_Aosta_ristorante_G_2_1_1_PQFMmWb.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22601_z_Alpechiara_Aosta_spa_G_2_1_1_GtstRa0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_dcjIYa7_tOcoJZH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_alpechiara_5"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "ALTITUDINE: 1110 metri.\n\nL'Hotel Alpechiara, realizzato in tipico stile valdostano con legni chiari e pietre a vista, è immerso nella natura e situato a 2,5 km dal centro di Pré-Saint-Didier, a circa 4 km da Courmayeur e a pochi chilometri dalla frontiera con la Francia e da Chamonix. Luogo ideale per una vacanza in montagna, circondati dalla pace di un tranquillo villaggio di montagna ai piedi del Monte Bianco.\n\nA disposizione degli ospiti reception 24h, 2 ascensori, sala ristorante (su richiesta vengono forniti prodotti per celiaci), bar, sala TV, giardino, terrazza, ping pong, connessione internet Wi-Fi nelle zone comuni (gratuita), parcheggio secondo disponibilità (gratuito), garage secondo disponibilità (10 euro al giorno da pagare in loco), deposito sci, servizio navetta da/per gli impianti di risalita ad orari prestabiliti (gratuito), mini club 3/10 anni nelle ore serali, culla per infant 0-2 anni non compiuti su richiesta al momento della prenotazione secondo disponibilità (8 euro al giorno da pagare in loco), piccola area benessere (aperto dalle 16:00 alle 19:00 - accesso consentito su prenotazione a partire dai 14 anni) con sauna e idromassaggio.\n\nLe camere Classic (circa 17 mq) dispongono di servizi privati con doccia o vasca, asciugacapelli, TV-Sat, telefono, riscaldamento, minibar (su richiesta), alcune camere con balcone (su richiesta secondo disponibilità). Possibilità di camere singole (su richiesta alla prenotazione – con supplemento come da tabella prezzi).\n\nAMICI A 4 ZAMPE: Ammessi di piccola taglia (50 euro a soggiorno da pagare in loco per pulizia finale), previa segnalazione al call center al momento della prenotazione.\n\nSKI AREA: Courmayeur-Mont Blanc. Distanza dagli impianti: 2,5 km.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/22596_z_Alpechiara_Aosta_sciatori1_G_2_1_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "SERVIZI ED ESPERIENZE INCLUSE",
                                    "data": {
                                        "specifiche": "da 4 a 7 notti + utilizzo piccolo centro benessere + miniclub, Mezza pensione, Utilizzo Spa/Centro Benessere, Cenone Di Capodanno, Cena Di Natale, Bonus Vacanze",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/22597_z_Alpechiara_Aosta_esterno_G_2_1_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm00758687",
                            "field1": "Cortina d'ampezzo (BL)",
                            "field2": "Hotel alaska",
                            "field3": "1, 3 o 7 notti mezza pensione",
                            "field4": "",
                            "description": "Cortina d'ampezzo (BL) Hotel alaska 1, 3 o 7 notti mezza pensione",
                            "grammage": null,
                            "price": "99.00",
                            "price_label": "Da 99,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Montagna",
                            "subcategory": "Montagna",
                            "blueprint": {
                                "top": 63.80267888740546,
                                "left": 27.645119418483894,
                                "width": 22.345181268076058,
                                "height": 25.977196742323024
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42769_z_Alaska_Cortina_esterno1_G_1_2_oo9U3sr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42770_z_Alaska_Cortina_reception_G_1_2_e3T0ewT.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42771_z_Alaska_Cortina_camera_G_1_2_Uh0yCSE.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42772_z_Alaska_Cortina_ristorante_G_1_2_MwWEuT2.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42773_z_Alaska_Cortina_img1_G_1_2_BUqtM1R.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_BrjeZhV_UiPMDRM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_alaska_6"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "ALTITUDINE: 1200 metri\n\nL'Hotel Alaska si trova nel centro di Cortina d'Ampezzo, vicino alla scuola di sci di Cortina, alla scuola di alpinismo e a molti altri negozi. Dista 100 metri dalla fermata dell'autobus.\n\nA disposizione degli ospiti reception 24h, ascensore, ristorante (su richiesta vengono forniti prodotti per celiaci e pasti vegani), bar, deposito sci/scarponi, deposito bici, sala TV, terrazza solarium, sala giochi, ping-pong, sala fitness, connessione Wi-Fi nelle zone comuni (gratuita), garage secondo disponibilità (20 euro al giorno da pagare in loco - non prenotabile), culla per infant 0-2 anni non compiuti su richiesta alla prenotazione secondo disponibilità (16 euro al giorno da pagare in loco).\n\nLe camere Standard dispongono di servizi privati con doccia, asciugacapelli, set di cortesia, riscaldamento, telefono, TV-Sat, cassaforte.\n\nAMICI A 4 ZAMPE: Ammessi di piccola taglia (16 euro al giorno da pagare in loco), su richiesta al call center al momento della prenotazione\n\nSKI AREA: Cortina d'Ampezzo. Distanza dagli impianti - Faloria: 300 metri (a piedi) / Tofana: 1,5 km.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/42769_z_Alaska_Cortina_esterno1_G_1_2.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "",
                                    "data": {
                                        "specifiche": "Mezza pensione con colazione servita e cena con servizio al tavolo con menu di 3 portate a scelta, Utilizzo della sala fitness, Connessione Wi-Fi nelle aree comuni.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/42770_z_Alaska_Cortina_reception_G_1_2.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm04172216",
                            "field1": "Pretoro (CH)",
                            "field2": "Hotel ti bionda suisse",
                            "field3": "2, 3, 4 o 5 notti, Pensione completa",
                            "field4": "",
                            "description": "Pretoro (CH) Hotel ti bionda suisse 2, 3, 4 o 5 notti, Pensione completa",
                            "grammage": null,
                            "price": "101.00",
                            "price_label": "Da 101,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Montagna",
                            "subcategory": "Montagna",
                            "blueprint": {
                                "top": 63.80267888740546,
                                "left": 49.99030068655995,
                                "width": 22.504855190605866,
                                "height": 25.977196742323024
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/32761_z_Ti_Bionda_Suisse_Pretoro_img1_G_1_1_2_1_pxJXVeY.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/12738_z_Ti_Bionda_Suisse_Pretoro_esterno_inverno2_G_1_1_1_1_2_1_SBbHv3q.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/12734_z_Ti_Bionda_Suisse_Pretoro_esterno_inverno_G_1_1_1_1_2_1_DcErTp2.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/9882_z_Ti_Bionda_Suisse_Pretoro_sala_G_1_1_1_1_1_2_1_KPCS6sV.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/9881_z_Ti_Bionda_Suisse_Pretoro_ristorante1_G_1_1_1_1_1_2_1_6bqFJrn.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/9880_z_Ti_Bionda_Suisse_Pretoro_camera_G_1_1_1_1_1_2_1_u1PBYUl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_2zjyN4h_rALQSyF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_ti_bionda_suisse_11"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "ALTITUDINE: circa 1300 metri.\n\nL'Hotel Ti Bionda Suisse si trova a Pretoro, presso la località di Passolanciano, a pochi metri dagli impianti sciistici. Il comprensorio offre piste adatte a tutti, dai professionisti ai più piccini, con la possibilità di ciaspolate pomeridiane e serali.\n\nLa struttura dispone di reception (08:00-22:00) con cassaforte centrale, ascensore, ristorante (su richiesta vengono forniti menu senza glutine), deposito biciclette, sala TV, bar, giardino, terrazza, parcheggio esterno secondo disponibilità (gratuito), connessione Wi-Fi nelle aree comuni (gratuita), seggiolone su richiesta alla prenotazione secondo disponibilità (gratuito), culla su richiesta alla prenotazione secondo disponibilità (15 euro al giorno da pagare in loco), garage secondo disponibilità (15 euro al giorno da pagare in loco), animazione (per soggiorni dal 19/12/2021 al 02/01/2022 - il servizio è disponibile venerdì, sabato e domenica dalle 10:00 alle 22:00).\n\nLe camere sono dotate di servizi privati, telefono, TV, connessione Wi-Fi (gratuita), alcune con balcone.\n\nAMICI A 4 ZAMPE: Non ammessi.\n\nSKI AREA: Majelletta. Distanza dagli impianti: direttamente sulle piste.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/12738_z_Ti_Bionda_Suisse_Pretoro_esterno_inverno2_G_1_1_1_1_2_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "",
                                    "data": {
                                        "specifiche": "da 2 a 5 notti, Pensione completa, Bimbo gratis, Cenone Di Capodanno, Pranzo Di Natale, Bonus Vacanze",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/32761_z_Ti_Bionda_Suisse_Pretoro_img1_G_1_1_2_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm36693355",
                            "field1": "Bardonecchia (TO)",
                            "field2": "Hotel rivè",
                            "field3": "3, 4 o 5 notti + 1 percorso benessere presso la \"Spa Mi Ami\"",
                            "field4": "",
                            "description": "Bardonecchia (TO) Hotel rivè 3, 4 o 5 notti + 1 percorso benessere presso la \"Spa Mi Ami\"",
                            "grammage": null,
                            "price": "208.00",
                            "price_label": "Da 208,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Montagna",
                            "subcategory": "Montagna",
                            "blueprint": {
                                "top": 63.80267888740546,
                                "left": 72.49515587716581,
                                "width": 22.224293531534723,
                                "height": 25.977196742323024
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/30615_z_Rive_Bardonecchia_VedutaPisteok_G_3_1_gwD3qVa.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/30618_z_Rive_Bardonecchia_Piscina_okG_3_1_bMi0Qa0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/34134_z_Rive_Bardonecchia_benessere3_G_3_1_ffqLMyH.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/21458_z_Rive_Bardonecchia_esterno2_G_1_3_1_7A2zyfg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/21459_z_Rive_Camera_G_1_3_1_0A5My40.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/21461_z_Rive_Bardonecchia_benessere2_G_1_3_1_h8oNLH9.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_HviHTGo_KXwsLMp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_rive_10"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "ALTITUDINE: 1320 metri\n\nL'Hotel Rivè si trova a circa 800 metri dal centro di Bardonecchia, di fronte alla partenza degli impianti di Campo Smith. Il comprensorio è un paradiso per gli sciatori e soprattutto per gli amanti dello snowboard grazie al Bardonecchia Snow Park con un’half pipe Olimpica e l'area Burton Progression Park, dedicata ai principianti.\n\nA disposizione degli ospiti reception 24h, servizio facchinaggio, ascensore, ristorante (su richiesta vengono forniti prodotti per celiaci e pasti vegani), deposito sci, sala TV, bar, internet point, connessione Wi-Fi nelle aree comuni (gratuita), giardino, terrazza, parcheggio secondo disponibilità (gratuito), garage secondo disponibilità (5 euro al giorno da pagare in loco), servizio baby-sitting (su richiesta e a pagamento in loco), culla per infant 0-3 anni non compiuti su richiesta alla prenotazione secondo disponibilità (10 euro al giorno da pagare in loco), centro benessere \"Spa Mi Ami\" (orari di apertura: dalle 15:00 alle 21:00 - accesso consentito ai bambini solo se accompagnati da un adulto) che comprende Hammam, saune finlandesi, docce emozionali, bagni di vapore, vasca di reazione, piscina con nuoto contro corrente, vasca idromassaggio jacuzzi, centro fitness, solarium (a pagamento), cabine per massaggi e trattamenti estetici (a pagamento).\n\nLe camere Standard (18 - 25 mq), dispongono di servizi privati con doccia o vasca, asciugacapelli, set di cortesia, riscaldamento, telefono, Tv-Sat, minibar, cassaforte, connessione Wi-Fi (gratuita), alcune con balcone. Le camere quadruple sono con letto a castello. Possibilità di camere singole (su richiesta alla prenotazione - con supplemento).\n\nAMICI A 4 ZAMPE: Ammessi di piccola taglia (10 euro al giorno da pagare in loco), su richiesta al call center al momento della prenotazione.\n\nSKI AREA: Campo Smith. Distanza dagli impianti: circa 50 metri.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/30615_z_Rive_Bardonecchia_VedutaPisteok_G_3_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "",
                                    "data": {
                                        "specifiche": "3- 4 o 5 notti + 1 percorso benessere presso la \"Spa Mi Ami\", Mezza pensione, Bimbo gratis, Utilizzo Spa/Centro Benessere, Bonus Vacanze",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/21458_z_Rive_Bardonecchia_esterno2_G_1_3_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/pages/page_2_xWds4pU.jpg",
                    "products": [
                        {
                            "item_id": "cstm49891670",
                            "field1": "Arco (TN)",
                            "field2": "Hoody active & happiness hotel",
                            "field3": "1 o 2 notti pernottamento e colazione + visita al frantoio e degustazione",
                            "field4": "+ utilizzo vasca idromassaggio e sauna",
                            "description": "Arco (TN) Hoody active & happiness hotel 1 o 2 notti pernottamento e colazione + visita al frantoio e degustazione + utilizzo vasca idromassaggio e sauna",
                            "grammage": null,
                            "price": "39.00",
                            "price_label": "Da 39,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Città",
                            "subcategory": "Città",
                            "blueprint": {
                                "top": 5.131833455362409,
                                "left": 5.276307466103278,
                                "width": 22.224293531534723,
                                "height": 28.282112784992215
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/58957_z_Hoody_Active_Happiness_Hotel_Arco_Arco_G_mz50a4r.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55021_z_Hoody_Active_Happiness_Hotel_Arco_terrazzo5_G_Z9wfK8A.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55020_z_Hoody_Active_Happiness_Hotel_Arco_tennistavolo_G_0nC81QN.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55019_z_Hoody_Active_Happiness_Hotel_Arco_colazioni_G_yhpOXJ8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55018_z_Hoody_Active_Happiness_Hotel_Arco_bar2_G_1gb1fuv.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55017_z_Hoody_Active_Happiness_Hotel_Arco_reception1_G_ys322hd.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55016_z_Hoody_Active_Happiness_Hotel_Arco_reception2_G_HDCM5Q0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/59030_z_Hoody_Active_Happiness_Hotel_Arco_terrazzo4_G_l1nNjsj.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55015_z_Hoody_Active_Happiness_Hotel_Arco_camera_G_lmTM9El.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55014_z_Hoody_Active_Happiness_Hotel_Arco_hall_G_gPs1Ezq.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55012_z_Hoody_Active_Happiness_Hotel_Arco_bici_G_hJTNIzL.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_CEnv3vO_qc9DTcf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hoody_active_happiness_hotel"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "L'Hoody Active & Happiness Hotel è una struttura moderna, in una zona tranquilla a pochi passi dal centro storico di Arco e a soli 5 Km da Riva del Garda. La cittadina di Torbole è collegata con Arco da una pista ciclabile immersa nei vigneti. La struttura è adatta ad una clientela adulta smart, indipendentemente dall'età. La stazione dei treni di Rovereto dista 18 km mentre l'aeroporto di Verona si trova a circa 100 km.\n\nA disposizione degli ospiti reception, ascensore, bar, terrazza solarium attrezzata con vasca idromassaggio e sauna, sala tv, spazio esterno attrezzato per il workout, deposito biciclette con officina, connessione Wi-Fi e ampio garage interrato.\n\nLe camere standard dispongono di servizi privati con doccia, bollitore, aria condizionata e balcone.\n\nAMICI A 4 ZAMPE: ammessi di piccola taglia (massimo 40 cm - 10 euro al giorno da pagare in loco - non ammesso nelle aree comuni), su richiesta al call center al momento della prenotazione.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/58957_z_Hoody_Active_Happiness_Hotel_Arco_Arco_G.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "Servizi",
                                    "data": {
                                        "specifiche": "Carte di Credito, Ascensore, Deposito Biciclette, Bar, WI-FI in aree comuni, Terrazza, Animali domestici ammessi (a pagamento), Colazione a buffet, Sauna, Idromassaggio, Servizi privati con doccia, Aria condizionata, Terrazza/balcone",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/55021_z_Hoody_Active_Happiness_Hotel_Arco_terrazzo5_G.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm35535763",
                            "field1": "Pistoia (PT)",
                            "field2": "Hotel villa cappugi",
                            "field3": "1 o 2 notti mezza pensione + utilizzo piscina esterna (da giugno a settembre)",
                            "field4": "+ 1 ingresso al museo \"Museo Pistoia Sotterranea\"",
                            "description": "Pistoia (PT) Hotel villa cappugi 1 o 2 notti mezza pensione + utilizzo piscina esterna (da giugno a settembre) + 1 ingresso al museo \"Museo Pistoia Sotterranea\"",
                            "grammage": null,
                            "price": "49.00",
                            "price_label": "Da 49,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Città",
                            "subcategory": "Città",
                            "blueprint": {
                                "top": 5.131833455362409,
                                "left": 27.500600997638006,
                                "width": 22.507672403395116,
                                "height": 28.282112784992215
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/31531_z_Villa_Cappuggi_Pistoia_esterno_G_1_V0CpAIW.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/31532_z_Villa_Cappuggi_Pistoia_camera1_G_1_dorTzwD.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/31533_z_Villa_Cappuggi_Pistoia_img_G_1_ZnhC6ZQ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/31534_z_Villa_Cappuggi_Pistoia_chiesa_G_1_9Pzw8Az.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/31535_z_Villa_Cappuggi_Pistoia_underground-mill_G_1_5E7zCWf.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/31536_z_Villa_Cappuggi_Pistoia_underground-caverns_G_1_t6xEAF7.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/44042_z_Villa_Cappuggi_Pistoia_corridoi_G_1_FNUwTaZ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/44043_z_Villa_Cappuggi_Pistoia_esterno4_G_1_C2RL0bK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/44044_z_Villa_Cappuggi_Pistoia_pool_G_1_OguRqw5.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/44045_z_Villa_Cappuggi_Pistoia_sala_colazioni_G_1_tJKboY4.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/44046_z_Villa_Cappuggi_Pistoia_esterno3_G_1_lolhfG9.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/44047_z_Villa_Cappuggi_Pistoia_ristorante1_G_1_futXX6l.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/44048_z_Villa_Cappuggi_Pistoia_hall_G_1_dGjpk6r.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/44049_z_Villa_Cappuggi_Pistoia_hotel_G_1_Yxq0cnD.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55019_z_Hoody_Active_Happiness_Hotel_Arco_colazioni_G_UgeQUPk_ouuN4XY.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_wUBjXgd_bF3SSQn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_villa_cappugi_4"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "L'Hotel Villa Cappugi si trova a 2 km dal centro storico di Pistoia in posizione collinare, contornato da piante di olivo e cipresso, caratteristici della campagna toscana. A breve distanza da Pistoia si trovano le città di Pisa (55 km), Firenze (55 km) e Lucca (40 km).\n\nLa struttura dispone di reception (07:00 - 23:30), servizio facchinaggio, 2 ascensori, ristorante, deposito biciclette, bar, giardino, internet point, connessione Wi-Fi nelle aree comuni (gratuita), parcheggio secondo disponibilità (gratuito), culla per infant 0-3 anni non compiuti su richiesta alla prenotazione secondo disponibilità (gratuita), palestra (gratuita), campo da tennis in sintetico, piscina esterna (aperta indicativamente da giugno a settembre secondo condizioni metereologiche; cuffia obbligatoria), servizio baby sitting (su richiesta e a pagamento in loco).\n\nLe camere dispongono di servizi privati con doccia o vasca, asciugacapelli, aria condizionata, telefono, Tv-Sat, minibar, cassaforte, connessione Wi-Fi (gratuita) e balcone. Sono disponibili in tipologia doppia Classic (16 mq) e tripla/quadrupla Deluxe (22 mq).\n\nAMICI A 4 ZAMPE: Ammessi cani di piccola taglia e gatti solo nelle camere Classic a piano terra (5 euro al giorno da pagare in loco), su richiesta al call center al momento della prenotazione.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/31531_z_Villa_Cappuggi_Pistoia_esterno_G_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "LA QUOTA COMPRENDE",
                                    "data": {
                                        "specifiche": "Mezza pensione con colazione a buffet assistito dolce e salato e cena con servizio al tavolo con menu fisso di 3 portate, Drink di benvenuto, Utilizzo della piscina esterna (aperta indicativamente da giugno a settembre secondo condizioni metereologiche; cuffia obbligatoria), Utilizzo della palestra, 1 biglietto d'ingresso al \"Museo Pistoia Sotterranea\" per persona per soggiorno (da ritirare direttamente presso la reception dell'hotel), Parcheggio secondo disponibilità, Connessione Wi-Fi, Culla per infant 0-3 anni non compiuti su richiesta alla prenotazione secondo disponibilità.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/31534_z_Villa_Cappuggi_Pistoia_chiesa_G_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm52294765",
                            "field1": "Valfabbrica (PG)",
                            "field2": "Castello di giomici",
                            "field3": "1 o 2 notti mezza pensione + visita guidata del castello",
                            "field4": "",
                            "description": "Valfabbrica (PG) Castello di giomici 1 o 2 notti mezza pensione + visita guidata del castello",
                            "grammage": null,
                            "price": "69.00",
                            "price_label": "Da 69,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Città",
                            "subcategory": "Città",
                            "blueprint": {
                                "top": 5.131833455362409,
                                "left": 50.00827340103313,
                                "width": 22.34519165664382,
                                "height": 28.282112784992215
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/33844_z_Castello_di_Giomici_Umbria_esterno4_G_1_kYzR3mX.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54507_z_Castello_di_Giomici_Umbria_sala_G_OGbzbie.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54506_z_Castello_di_Giomici_Umbria_ristorante2_G_LVEH1S6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54505_z_Castello_di_Giomici_Umbria_ristorante1_G_PGPjF8Y.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54504_z_Castello_di_Giomici_Umbria_cappella_G_RmhvRhG.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54503_z_Castello_di_Giomici_Umbria_camino_G_2sloY71.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/54502_z_Castello_di_Giomici_Umbria_camera4_G_1Knh9ME.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/47036_z_Castello_di_Giomici_Umbria_terrazzo1_G_eLXnkc6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/33843_z_Castello_di_Giomici_Umbria_interno1_G_1_QEipCId.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/33845_z_Castello_di_Giomici_Umbria_esterno3_G_1_bso7FHa.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/45596_z_Castello_di_Giomici_Umbria_camera3_G_pCszKpi.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/47033_z_Castello_di_Giomici_Umbria_terrazzo2_G_Vwm6hmv.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/47034_z_Castello_di_Giomici_Umbria_img1_G_5rOW4T9.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/47035_z_Castello_di_Giomici_Umbria_ristorante_G_vYIaiVO.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/33838_z_Castello_di_Giomici_Umbria_veduta_G_1_fzsgNkB.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/33840_z_Castello_di_Giomici_Umbria_piscina1_G_1_JYKX0k1.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/33842_z_Castello_di_Giomici_Umbria_esterno2_G_1_u35g8v4.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_vN5LdKQ_b2Z2GLb.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/castello_di_giomici_3"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Il Castello di Giomici è una fortezza medievale risalente al secolo XI, costruita per l’avvistamento dei nemici e la difesa della Valle del Chiascio dagli attacchi di Perugia e Assisi nemici storici di Gubbio. Tenute in buone condizioni, ci sono 2 torri, alcune case e la chiesetta del 1154 che ospita affreschi eseguiti tra il 1200 ed il 1600. La ristrutturazione del Castello insieme alla Torre, alla chiesetta, alle mura di cinta e delle vicine fattorie è stata fatta preservandone le caratteristiche architettoniche e storiche. Situato a circa 6 km da Valfabbrica, nel cuore dell’Umbria, il Castello è breve distanza dalle più belle città dell’Umbria: Assisi, Gubbio, Perugia, Orvieto, Spoleto e Todi.\n\nLa struttura dispone di reception (dalle 8 alle 24), ristorante, parco con due laghetti in cui è possibile praticare pesca sportiva, piscina esterna (aperta da giugno a settembre secondo condizioni meteo) attrezzata con ombrelloni e lettini (secondo disponibilità), terrazza, deposito e noleggio biciclette (su richiesta e a pagamento in loco), ping pong, calcio balilla, maneggio (a 2 minuti a piedi dal castello), culla per infant 0-2 anni non compiuti su richiesta alla prenotazione secondo disponibilità (gratuita), connessione Wi-fi (gratuita), parcheggio secondo disponibilità (gratuito). \n\nLe camere Deluxe (15 mq) possono essere situate al primo piano, con pareti a vista e un'ambientazione austera ma molto caratteristica, oppure al secondo piano, più luminose e con una splendida vista sulle colline circostanti. Dispongono tutte di servizi privati con doccia, asciugacapelli, TV-Sat. Occupazione massima: 2 adulti + 1 bambino fino a 12 anni non compiuti.\n\nAmici a 4 zampe : Ammessi di piccola e media taglia (20 euro a soggiorno da pagare in loco), su richiesta al call center al momento della prenotazione.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/33842_z_Castello_di_Giomici_Umbria_esterno2_G_1_EKsxc4w.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "LA QUOTA COMPRENDE",
                                    "data": {
                                        "specifiche": "Mezza pensione con prima colazione a buffet e cena in ristorante all'interno del Castello a base di prodotti tipici locali e con menù fisso servito al tavolo con menu fisso (1 primo + 1 secondo con contorno- frutta o dolce- caffè), Acqua in caraffa a cena, Aperitivo di benvenuto con degustazione di prodotti tipici locali (per durata soggiorni minimo 3 notti), 1 visita guidata del castello a soggiorno, Utilizzo piscina scoperta (aperta da giugno a settembre secondo condizioni meteo) con area solarium attrezzata di ombrelloni e lettini (secondo disponibilità), Utilizzo del ping pong e del calcio balilla, Connessione Wi-Fi, Parcheggio secondo disponibilità, Culla per infant 0-2 anni non compiuti su richiesta alla prenotazione secondo disponibilità",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/33844_z_Castello_di_Giomici_Umbria_esterno4_G_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm46777897",
                            "field1": "Aci trezza (CT)",
                            "field2": "Sicilia's art hotel & spa",
                            "field3": "1 notte + utilizzo piscina + 1 ingresso al centro benessere",
                            "field4": "",
                            "description": "Aci trezza (CT) Sicilia's art hotel & spa 1 notte + utilizzo piscina + 1 ingresso al centro benessere",
                            "grammage": null,
                            "price": "49.00",
                            "price_label": "49,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Villaggi",
                            "subcategory": "Villaggi",
                            "blueprint": {
                                "top": 5.131833455362409,
                                "left": 72.35346505767694,
                                "width": 22.689753319610585,
                                "height": 28.282112784992215
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55051_z_Sicilias_Art_Hotel_Spa__Aci_Catena_Sicilia_esterno1_G_1_1_eCDOfvd.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55050_z_Sicilias_Art_Hotel_Spa_Aci_Catena_Sicilia_bagno_G_1_1_oSKvyTd.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55049_z_Sicilias_Art_Hotel_Spa_Aci_Catena_Sicilia_superior_G_1_1_MerYfbN.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55057_z_Sicilias_Art_Hotel_Spa__Aci_Catena_Sicilia_terrazzo_G_1_1_vxamhzV.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55056_z_Sicilias_Art_Hotel_Spa__Aci_Catena_Sicilia_sauna_G_1_1_5Hdol3c.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55055_z_Sicilias_Art_Hotel_Spa__Aci_Catena_Sicilia_ristorante_G_1_1_EyYiMTk.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55054_z_Sicilias_Art_Hotel_Spa__Aci_Catena_Sicilia_piscina_G_1_1_s0Z7VjS.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55053_z_Sicilias_Art_Hotel_Spa__Aci_Catena_Sicilia_idromassaggio_G_1_Zf5pZls.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/55052_z_Sicilias_Art_Hotel_Spa__Aci_Catena_Sicilia_esterno2_G_1_1_AUi2EQp.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_QSDuhwO_7nqR4Oz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/sicilias_art_hotel_spa_3"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Il Sicilia's Hotel Art & Spa si trova a 5 km dal centro di Aci Trezza, in posizione panoramica, a circa 1,5 km dal mare. E' punto di partenza ideale per esplorare le meraviglie artistiche e naturalistiche siciliane: dista circa 15 km da Catania, 45 km da Taormina, 30 km dall'Etna e 80 km da Siracusa.\n\nA disposizione degli ospiti reception 24 (aperta dalle 07:30 alle 21:30), sala TV, bar, connessione Wi-Fi nelle aree comuni (gratuita), parcheggio secondo disponibilità (gratuito), piscina esterna attrezzata con ombrelloni e lettini secondo disponibilità (aperta in base a condizioni meterelogiche), centro benessere (aperto dalle 09 alle 19) con sauna finlandese, idromassaggio con cromoterapia, doccia a secchio, getto kneipp, tisaneria e angolo relax con zona lettura.\n\nLe camere Suoerior (circa 20 mq) dispongono di servizi privati con doccia, asciugacapelli, set di cortesia, aria condizionata, TV-Sat, cassaforte, connessione Wi-Fi (gratuita).\n\nSupplemento Camera singola (su richiesta al call center): 33 euro a notte\n\nAMICI A 4 ZAMPE: Ammessi di piccola taglia (30 euro a soggiorno da pagare in loco), previa segnalazione al call center al momento della prenotazione.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/55051_z_Sicilias_Art_Hotel_Spa__Aci_Catena_Sicilia_esterno1_G_1_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "SERVIZI ED ESPERIENZE INCLUSE",
                                    "data": {
                                        "specifiche": "1 notte + utilizzo piscina + 1 ingresso al centro benessere, Pernottamento e colazione, Utilizzo Spa/Centro Benessere, Utilizzo Piscina",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/55052_z_Sicilias_Art_Hotel_Spa__Aci_Catena_Sicilia_esterno2_G_1_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm00872735",
                            "field1": "Fuggi (FR)",
                            "field2": "Hotel delle terme & spa",
                            "field3": "1 notte + utilizzo idromassaggio + 1 percorso benessere + 1 bottiglia di Acqua di Fiuggi",
                            "field4": "",
                            "description": "Fuggi (FR) Hotel delle terme & spa 1 notte + utilizzo idromassaggio + 1 percorso benessere + 1 bottiglia di Acqua di Fiuggi",
                            "grammage": null,
                            "price": "69.00",
                            "price_label": "Da 69,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Città",
                            "subcategory": "Città",
                            "blueprint": {
                                "top": 33.41394624035462,
                                "left": 72.35346505767694,
                                "width": 22.689753319610585,
                                "height": 28.2520527259867
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42283_z_Albergo_Delle_Terme_Fiuggi_spa_G_1_1_r71FXrl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/11964_z_Albergo_Delle_Terme_Fiuggi_fiuggi_G_2_1_1_1_YKtM9cn.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/11965_z_Albergo_Delle_Terme_Fiuggi_ristorante_G_2_1_1_1_lRYOTfY.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42211_z_Albergo_Delle_Terme_Fiuggi_buffet1_G_1_1_9rrFO4h.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42212_z_Albergo_Delle_Terme_Fiuggi_camera2_G_1_1_QF0KbL5.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42281_z_Albergo_Delle_Terme_Fiuggi_spa3_G_1_1_Vmk4dsl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42282_z_Albergo_Delle_Terme_Fiuggi_spa2_G_1_1_UNqLOsw.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42284_z_Albergo_Delle_Terme_Fiuggi_spa1_G_1_1_BsYinpl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/42285_z_Albergo_Delle_Terme_Fiuggi_bar_G_1_1_dnZ6gbr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_dK2RM00_fifB3uD.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_delle_terme_spa_4"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "L'Hotel delle Terme & Spa sorge nel centro di Fiuggi Fonte, accanto al famoso Grand Hotel Palazzo della Fonte. Si trova in una posizione strategica tra i due parchi termali della città: la Fonte Bonifacio VIII e la Fonte Anticolana, nei quali è possibile passeggiare sorseggiando la famosa acqua. Dista circa 30 km da Frosinone e circa 1 ora di auto da Roma.\n\nA disposizione degli ospiti reception, servizio facchinaggio (1,50 euro a valigia da pagare in loco), ascensore, ristorante, bar, sala TV, giardino, terrazza, connessione Wi-Fi nelle aree comuni (gratuita), parcheggio secondo disponibilità (gratuito), culla per infant 0-3 anni non compiuti su richiesta alla prenotazione secondo disponibilità (10 euro al giorno da pagare in loco), idromassaggio esterno sul tetto (utilizzo gratuito per massimo 90 minuti al giorno - secondo condizioni metereologiche), centro benessere con vasca idrosalina con idromassaggi e bolle, sauna, tunnel docce emozionali, doccia svedese, parete di sale rosa dell'Himalaya, zona relax con tisane e acqua rivitalizzata, hammam, cabine per rituali benessere, fanghi e massaggi (alcuni servizi sono a pagamento in loco).\n\nLe camere Standard (12-20 mq) dispongono di servizi privati con doccia, asciugacapelli, telefono, Tv-Sat, minibar, cassaforte, connessione Wi-Fi (gratuita). Le camere singole sono con letto alla francese.\n\nAMICI A 4 ZAMPE: Ammessi di piccola taglia (10 euro al giorno da pagare in loco), previa segnalazione al call center al momento della prenotazione.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/42283_z_Albergo_Delle_Terme_Fiuggi_spa_G_1_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "SERVIZI ED ESPERIENZE INCLUSE",
                                    "data": {
                                        "specifiche": "1 notte + utilizzo idromassaggio + 1 percorso benessere + 1 bottiglia di Acqua di Fiuggi, Mezza pensione, Utilizzo Spa/Centro Benessere, Cenone Di Capodanno, Cena Di Natale, Sconto Servizi, Bonus Vacanze",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/11964_z_Albergo_Delle_Terme_Fiuggi_fiuggi_G_2_1_1_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm66394470",
                            "field1": "Sanremo (IM)",
                            "field2": "Grand hotel & des anglais",
                            "field3": "2 o 3 notti pernottamento e colazione + 1 cena + 1 ingresso al centro benessere",
                            "field4": "",
                            "description": "Sanremo (IM) Grand hotel & des anglais 2 o 3 notti pernottamento e colazione + 1 cena + 1 ingresso al centro benessere",
                            "grammage": null,
                            "price": "165.00",
                            "price_label": "Da 165,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Città",
                            "subcategory": "Città",
                            "blueprint": {
                                "top": 33.41394624035462,
                                "left": 50.06945342502212,
                                "width": 22.284011632654824,
                                "height": 28.2520527259867
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/40133_z_Des_Anglais_Sanremo_facciata_G_1_qOiw6OG.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/40135_z_Des_Anglais_Sanremo_hall2_G_1_rs20ffs.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/40136_z_Des_Anglais_Sanremo_entrata_G_1_UNYmiKg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/40137_z_Des_Anglais_Sanremo_camera1_G_1_5I42W7G.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/40138_z_Des_Anglais_Sanremo_ristorante1_G_1_QgMxQn4.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/40139_z_Des_Anglais_Sanremo_veduta1_G_1_z1nQcwY.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/40140_z_Des_Anglais_Sanremo_hall1_G_1_5zNBsO8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_QrKLyN6_3Jf7bjL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/grand_hotel_des_anglais_3"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Il Grand Hotel & Des Anglais di Sanremo si trova in posizione tranquilla e panoramica, a circa 200 metri dal mare e 300 metri dal centro. La stazione ferroviaria dista circa 2 km.\n\nA disposizione degli ospiti reception, ascensore, bar, ristorante, sala comune/zona TV, giardino, parcheggio secondo disponibilità (gratuito), culla per infant 0-3 anni non compiuti su richiesta al momento della prenotazione secondo disponibilità (gratuita), connessione Wi-Fi (gratuita), centro benessere (accesso consentito ai maggiori di 16 anni) con sauna finlandese, bagno turco e aromarium (mix di vapore con essenze aromatiche), docce emozionali, cromoterapia, l’antico Frigidarium (nebbia gelata, doccia fredda, cascata fredda e cascata di ghiaccio), area piscina con idromassaggio (con funzione cardio e fontana per cervicale e geyser), due aree relax, cabine per trattamenti e massaggi (a pagamento).\n\nLe camere dispongono di servizi privati con doccia, asciugacapelli, telefono con linea diretta, minibar, TV-Sat, cassaforte, aria condizionata, connessione Wi-Fi (gratuita).\n\nAMICI A 4 ZAMPE: Ammessi di piccola taglia (massimo 1 per camera - 15 euro a soggiorno da pagare in loco), previa segnalazione al call center al momento della prenotazione.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/40133_z_Des_Anglais_Sanremo_facciata_G_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "LA QUOTA COMPRENDE",
                                    "data": {
                                        "specifiche": "Pernottamento e colazione americana a buffet, 1 cena per persona per soggiorno, 1 ingresso di 3 ore al centro benessere a persona a soggiorno, Connessione Wi-Fi, Parcheggio secondo disponibilità, Culla per infant 0-3 anni non compiuti su richiesta al momento della prenotazione secondo disponibilità.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/40136_z_Des_Anglais_Sanremo_entrata_G_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm81607575",
                            "field1": "Monterenzio (BO)",
                            "field2": "Villaggio della salute più",
                            "field3": "1 notti mezza pensione + bevande + ingresso alle Terme dell'Agriturismo",
                            "field4": "+ ingresso all'Acquapark Termale",
                            "description": "Monterenzio (BO) Villaggio della salute più 1 notti mezza pensione + bevande + ingresso alle Terme dell'Agriturismo + ingresso all'Acquapark Termale",
                            "grammage": null,
                            "price": "79.00",
                            "price_label": "Da 79,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Villaggi",
                            "subcategory": "Villaggi",
                            "blueprint": {
                                "top": 33.41394624035462,
                                "left": 27.621501889864472,
                                "width": 22.447951535157642,
                                "height": 28.2520527259867
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22489_z_Villaggio_della_Salute_Monterenzio_piscina3_G_1_1_gYxPTNY.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22488_z_Villaggio_della_Salute_Monterenzio_panorama1_G_1_1_xvM54Zr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22491_z_Villaggio_della_Salute_Monterenzio_camera_G_2_1_N4L4Vhz.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22492_z_Villaggio_della_Salute_Monterenzio_ristorante_G_2_1_yfSjpW9.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/22493_z_Villaggio_della_Salute_Monterenzio_piscina2_G_1_1_PdiqGbX.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_YcAabau_HmotN9x.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/villaggio_della_salute_piu_5"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Il Villaggio della Salute Più si trova a Monterenzio, a 15 km da Castel San Pietro Terme.\n\nLa struttura, immersa nel verde, dispone di reception, ristorante \"Sillaro\" (su richiesta vengono forniti prodotti per celiaci e piatti vegani), bar \"la Stalla\", sala TV, deposito e noleggio mountain bike secondo disponibilità (gratuito), area giochi per bambini, negozio con possibilità di acquisto di prodotti dell'azienda agricola, campi sportivi regolamentari per calcio, calcio a 5, beach volley (a pagamento in loco), lago per la pesca sportiva, culla per infant 0-3 anni non compiuti su richiesta al momento della prenotazione secondo disponibilità (gratuita), connessione internet Wi-Fi nelle zone comuni (gratuita), parcheggio fotovoltaico secondo disponibilità (gratuito), \n\nIl territorio del Villaggio della Salute Più ospita diversi antichi casali restaurati, tutti legati alla ricca storia della Val Sillaro. Le camere in albergo diffuso con formula \"club house\" sono disponibili a una distanza variabile tra 500 e 900 metri dal cuore del villaggio, raggiungibile con una passeggiata, in bicicletta o in auto. I casali sono: Ca' Ida, Ca' Ida Piccola, Ca' Nova, Ca' Mulino, Ca' Sillaro. Le camere dispongono di servizi privati con doccia, asciugacapelli, telefono, aria condizionata, TV-Sat, minibar. L'assegnazione del casale avverrà in loco a discrezione della struttura e in base alla disponibilità.\n\nAMICI A 4 ZAMPE: non ammessi.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/22489_z_Villaggio_della_Salute_Monterenzio_piscina3_G_1_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "SERVIZI ED ESPERIENZE INCLUSE",
                                    "data": {
                                        "specifiche": "1 o 4 notti + ingresso alle Terme dell'Agriturismo + ingresso all'Acquapark Termale, Mezza pensione con bevande, Bimbo gratis, Utilizzo Spa/Centro Benessere, Ingresso Alle Terme, Utilizzo Piscina, Attrazione Inclusa, Sconto Servizi, Bonus Vacanze",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/22493_z_Villaggio_della_Salute_Monterenzio_piscina2_G_1_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm16792446",
                            "field1": "Abano terme (PD)",
                            "field2": "Hotel abano ritz",
                            "field3": "3 o 4 notti pensione completa + utilizzo centro benessere + 1 bagno termale",
                            "field4": "",
                            "description": "Abano terme (PD) Hotel abano ritz 3 o 4 notti pensione completa + utilizzo centro benessere + 1 bagno termale",
                            "grammage": null,
                            "price": "280.00",
                            "price_label": "Da 280,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Città",
                            "subcategory": "Città",
                            "blueprint": {
                                "top": 33.41394624035462,
                                "left": 4.974795881882354,
                                "width": 22.646706007982118,
                                "height": 28.2520527259867
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/466_z_Ritz_Abano_PiscinaInterna_G_2_1_1_1_1_1_1_1_1_1_4_3_1_1_1_1_qcOwFHB.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/465_z_Ritz_Abano_Piscina2_G_2_1_1_1_1_1_1_1_1_1_4_3_1_1_1_1_85kWflE.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/464_z_Ritz_Abano_Piscina_scoperta_G_2_1_1_1_1_1_1_1_1_1_4_3_1_1_1_1_Qv2MhSn.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/463_z_Ritz_Abano_hall_G_2_1_1_1_1_1_1_1_1_1_4_3_1_1_1_1_kjOaNZ3.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/462_z_Ritz_Abano_Camera_G_2_1_1_1_1_1_1_1_1_1_4_3_1_1_1_1_ISkfxKs.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/461_z_Ritz_Abano_Benessere_G_2_1_1_1_1_1_1_1_1_1_4_3_1_1_1_1_VEEOeLS.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_FHlLSno_LPVCiqm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/hotel_abano_ritz_16"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "L'Hotel Abano Ritz è una struttura di fascino ed eleganza, caratterizzata da un ambiente esclusivo e ricco di atmosfera, con una gestione familiare che da generazioni si occupa di ospitalità.\n\nA disposizione degli ospiti reception 24h, servizio di facchinaggio, ascensore, ristorante (su richiesta viene fornito menu per bambini), deposito biciclette, noleggio biciclette secondo disponibilità (gratuito), sala TV, bar, internet point, connessione Wi-Fi nelle aree comuni (gratuita), giardino, area giochi, biliardo (gratuito), parcheggio secondo disponibilità (gratuito), culla su richiesta alla prenotazione secondo disponibilità (gratuita), garage secondo disponibilità (8 euro al giorno da pagare in loco), servizio baby-sitting, centro benessere con 2 piscine semi-olimpioniche con acqua termale (coperta e scoperta, comunicanti tra loro - la piscina esterna è attrezzata con lettini e ombrelloni secondo disponibilità; cuffia obbligatoria), zona idromassaggio, cascata cervicale, sauna, palestra attrezzata Technogym, massaggi e trattamenti estetici (a pagamento).\n\nLe camere Elegant sono dotate di servizi privati con doccia o vasca, telefono, TV Sat, radio, aria condizionata, connessione Wi-Fi (gratuita), minibar (consumazioni a pagamento), cassaforte.\n\nAMICI A 4 ZAMPE: Ammessi di piccola taglia (25 euro a soggiorno da pagare in loco), previa segnalazione al call center al momento della prenotazione.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/466_z_Ritz_Abano_PiscinaInterna_G_2_1_1_1_1_1_1_1_1_1_4_3_1_1_1_1.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "SERVIZI ED ESPERIENZE INCLUSE",
                                    "data": {
                                        "specifiche": "3 o 4 notti + utilizzo centro benessere + 1 bagno termale, Pensione completa, Bimbo gratis, Utilizzo Spa/Centro Benessere, Utilizzo Piscina, Trattamento Wellness, Sconto Servizi",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/464_z_Ritz_Abano_Piscina_scoperta_G_2_1_1_1_1_1_1_1_1_1_4_3_1_1_1_1.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm68853942",
                            "field1": "Italia, Spagna e Francia",
                            "field2": "Msc fantasia",
                            "field3": "7 notti da Genova o Napoli + tasse portuali + assicurazione medico/ bagaglio/ annullamento",
                            "field4": "",
                            "description": "Italia, Spagna e Francia Msc fantasia 7 notti da Genova o Napoli + tasse portuali + assicurazione medico/ bagaglio/ annullamento",
                            "grammage": null,
                            "price": "499.00",
                            "price_label": "Da 499,00€",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Crociera",
                            "subcategory": "Crociera",
                            "blueprint": {
                                "top": 61.66599896634133,
                                "left": 4.974795881882354,
                                "width": 89.74465352681821,
                                "height": 34.62142724648992
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/74753_z_MSC13012344_wSn1pJ0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/74754_z_MSC13012070_mwxwhEF.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/74755_z_MSC13012131_cfg1BQa.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/74756_z_MSC13012371_PJ48PN2.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/74757_z_MSC13012294_PfU3OCp.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/74758_z_MSC13012451_8J0qDsv.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/74759_z_MSC13012482_CTv42E8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/74761_z_MSC13012707_wUhmqNK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/74762_z_MSC18015836_qBbBpI2.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/74763_z_MSC18015839_FqKY5Un.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/products/cropped_image_FUb29AG_IKi3h4n.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.eurospin-viaggi.it/msc_fantasia"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Un itinerario di 7 notti tra Italia, Francia e Spagna per festeggiare Capodanno e scoprire Marsiglia con il suo spirito provenzale, Barcellona con le suggestive opere di Gaudì, la bella Palma di Maiorca con il suo vitale centro storico, la verace Napoli e infine La Spezia, per raggiungere con un’escursione le famose Cinque Terre.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/74753_z_MSC13012344.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "",
                                    "data": {
                                        "specifiche": "7 notti da Genova o Napoli + tasse portuali + assicurazione medico/bagaglio/annullamento, Pensione completa, Utilizzo Piscina, Tasse E Assicurazione Incluse, Animazione Inclusa",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/images/74761_z_MSC13012707.jpg"
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "https://interattivo.s3.amazonaws.com/h3Yh2Q42QL/pages/Fine-Eurospin-Viaggi_bE6nOjZ.png",
                    "products": []
                }
            ]
        }

    }

}

const lefletSwitch = () => {
    return leaflets.demo;
}

export const serverResponse = lefletSwitch();